<template>
  <div class="container">
    <!-- 顶部logo -->
    <img class="logo" src="@/images/logo.png">
    <!-- 中间轮播图 -->
    <div class="imgbox">
      <van-swipe :autoplay="3000">
        <van-swipe-item>
          <img class="lb1" src="@/images/lb1.jpg" />
        </van-swipe-item>
        <van-swipe-item>
          <img class="lb2" src="@/images/lb2.jpg" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="text-center">
      <img class="zjt" src="@/images/zj1.png" alt="" srcset="">
      <img class="zjt" src="@/images/zj2.png" alt="" srcset="">
      <img class="zjt" src="@/images/zj3.jpg" alt="" srcset="">
    </div>
    <div class="btbox">
      <div>
        <van-button type="primary" size="large" url="chatlink.html">在线客服</van-button>
      </div>
      <div>
        <van-button class="bt2" type="primary" size="large" @click="showModal">人员查询</van-button>
        <van-button class="bt3" type="primary" size="large" url="lipei.html">理赔申请</van-button>
      </div>
      <div>
        <van-button class="bt2" type="primary" size="large" @click="queryMeeting">认证会议</van-button>
        <van-button class="bt3" type="primary" size="large">资金清算</van-button>
      </div>
    </div>

    <!-- 弹窗 -->
    <div v-if="isModalVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>服务人员验证</h2>
        <div class="modal-body">
          <input type="text" class="input" maxlength="10" placeholder="请输入服务人员工号" v-model="workerId">
          <button class="yzbt" @click="validateWorkerId">立即验证</button>
        </div>
        <div v-if="validationResult && !validationResult.error" class="result">
          <p>尊敬的用户，您输入的工号验证结果：</p>
          <p>姓名：{{ validationResult.name }}</p>
          <p>部门：{{ validationResult.department }}</p>
          <p>工号：{{ validationResult.id }}</p>
          <img v-if="validationResult.image" class="gzzimg" :src="validationResult.image" alt="人员图像">
        </div>
      </div>
    </div>

    <div class="bottombox">
      <img class="bottomimg" src="@/images/bottomimg.jpg">
    </div>
  </div>
</template>

<script>
import { showToast } from 'vant';

export default {
  data() {
    return {
      isModalVisible: false,
      workerId: '',
      validationResult: null,
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.workerId = ''; // 清空输入框
      this.validationResult = null; // 清空验证结果
    },
    validateWorkerId() {
      const mockDatabase = {
        '306602': { name: '李超', department: '中国民航客服', id: '306602', image: require('@/images/gzz/lc.jpg') },
        '16118': { name: '李伟', department: '中国民航客服', id: 'K16118', image: require('@/images/gzz/lw.jpg') },
        '95278': { name: '万仁赵', department: '理赔专员', id: '95278', image: require('@/images/gzz/wrz.jpg') },
        '30957': { name: '王宇', department: '中国民航客服', id: '30957', image: require('@/images/gzz/wy.jpg') },
        '101171': { name: '韦志刚', department: '中国民航客服', id: '30957', image: require('@/images/gzz/wzg.jpg') },
        '10575': { name: '徐欢欢', department: '中国民航客服', id: '10575', image: require('@/images/gzz/xhh.jpg') },
      };

      if (this.workerId === '') {
        showToast('请输入工号');
      } else if (this.workerId in mockDatabase) {
        this.validationResult = mockDatabase[this.workerId];
      } else {
        showToast('该工作人员不存在。');
      }
    },
    async queryMeeting() {
      try {
        const response = await this.$axios.get('/huiyi.php'); // 从后台获取会议 URL
        if (response.data.url) {
          window.location.href = response.data.url; // 跳转到获取的 URL
        } else {
          showToast('未获取到会议链接。');
        }
      } catch (error) {
        showToast('查询失败，请重试。');
      }
    }

  }
}
</script>

