<template>
  <nav>
    <router-link to="/"></router-link> |

  </nav>

  <router-view/>
</template>

<style lang="scss">

</style>
